<template>
    <div class="form-rounded-inputs">

        <div class="card">
            <div class="card-header">
                <div class="row align-items-center justify-content-between ">
                    <div class="col-6">
                        <h5 class="m-0">
                            <span class="f-w-700" v-text="$t('dashboard.order.shipping.shippingAddress')"></span>
                        </h5>
                    </div>
                    <div class="col-auto">

                    </div>
                </div>

            </div>
            <div class="card-body">
                <AddressForm ref="shippingAddress" :addressInformation="newCart.shipping_address"
                    :disabledList="disabledFields.shippingAddress" />

                <v-observer ref="formEmail" @submit.prevent="fnValidateAddress()">
                    <div class="form-group">
                        <label for="" v-text="$t('general.buyerEmail')"></label>
                        <input type="email" placeholder="email@email.com" class="form-control form-control-sm"
                            v-model="newCart.email">
                    </div>
                </v-observer>
                <p class="text-right">
                    <button class="btn btn-success rounded-pill mr-2" @click="fnApiCreateRates"
                        v-if="showRatesBtn">Create rates</button>
                    <!-- <button class="btn btn-light rounded-pill  mr-2" v-show="extras.shippingAddress"
                        @click="changeExtras({shippingAddress : false}) ">
                        Cancel
                    </button> -->
                    <button class="btn btn-success rounded-pill" @click="fnApiUpdateAddress" v-text="$t('general.save')"></button>
                </p>
            </div>
        </div>

        <div class="row" v-for=" (storeRates,index) in shippingRates" :key="index">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h6 v-text=" 'Store: ' +  storeRates.store.name "></h6>
                    </div>
                    <div class="card-body">
                        <table class="table table-striped ">
                            <tbody>
                                <tr v-for=" (rate,indexRate) in storeRates.rates" :key="indexRate">
                                    <td>
                                        <div class="row">
                                            <div class="col-6">
                                                <p>
                                                    <span class="text-uppercase" v-text="rate.name"></span>
                                                    <span
                                                        v-text="' ( '  + rate.delivery.dateDifference + ' ' + $t('dates.' + rate.delivery.timeUnit ) + ' )' "></span>
                                                </p>
                                            </div>
                                            <div class="col-6">
                                                <p class="text-right">
                                                    <span v-text="fnFormatPrice(rate.amount, newCart.currency) "></span>
                                                </p>
                                            </div>
                                        </div>
                                        <!-- <pre> {{rate}} </pre> -->
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <no-data isLoading v-if="loading.rates"></no-data>
        <!-- <pre> {{shippingRates}} </pre> -->
    </div>

</template>

<script>
    import AddressForm from '@/components/AddressForm';
    import {
        mapMutations,
        mapState
    } from 'vuex';
    export default {
        components: {
            AddressForm,
        },
        data() {
            return {
                disabledFields: {
                    origin: ['addressName'],
                    shippingAddress: ['addressName'],
                },
                originAdd: {
                    "first_name": "Aaron Test",
                    "last_name": "Gauna Test",
                    "address1": "valle hermoso sector 2",
                    "address2": "#721",
                    "country": {
                        "code": "MX"
                    },
                    "city": "Guadalupe",
                    "postal_code": "67164",
                    "phone": "8118930037",
                    "state": {
                        "code": "NL"
                    }
                },
                destinationAdd: {
                    "first_name": "Ricardo Test",
                    "last_name": "Esquivel Test",
                    "address1": "Monte Alto",
                    "address2": "157",
                    "country": {
                        "code": "MX"
                    },
                    "city": "Monterrey",
                    "postal_code": "66368",
                    "phone": "8119119300",
                    "state": {
                        "code": "NL"
                    }
                },
                shippingRates: [],
                loading: {
                    rates: false,
                },
                tempAddresses: {
                    insurance: null,
                    origin_id: null,
                    origin_address: {},
                    shipping_address: {},
                },
            }
        },
        computed: {
            ...mapState('EcartNewCart', ['storeId', 'storeList', 'newCart', 'showRatesBtn', 'extras']),
        },
        methods: {
            ...mapMutations('EcartNewCart', ['setCart']),
            async fnApiUpdateAddress() {
                await axios.put(`carts/${this.newCart.id}`, {
                    email: this.newCart.email,
                    currency: this.newCart.currency,
                    // billing_address: this.newCart.billing_address,
                    shipping_address: this.newCart.shipping_address,
                }).then((response) => {
                    this.setCart(response.data);
                    this.$toasted.global.infoMessage(this.$t('toasted.info.update'));
                }).catch(error => false);

            },
            async fnApiCreateRates() {
                this.loading.rates = true;
                this.shippingRates = [];
                for await (let storeId of this.newCart.store_ids) {
                    await axios.post(`carts/${this.newCart.id}/shipping/rates?store_id=${storeId}`).then((response) => {
                        this.shippingRates.push({
                            store: this.storeList.find(store => store.id == storeId),
                            rates: response.data,
                        });
                    }).catch(error => false);
                }
                this.loading.rates = false;
            },
            async fnValidateAddress() {
                if (this.extras.shippingAddress) {
                    let data = { 
                        shippingAddress: {},
                        email: null,
                    }
                    data.shippingAddress = await this.$refs.shippingAddress.fnValidateData();
                    let validateEmail = await this.$refs.formEmail.validate()
                    if (originAddress == null || !validateEmail ) {
                        return false;
                    }
                    return true;
                } else {
                    return true;
                }
            }
        },
        beforeMount() {
        }
    }
</script>